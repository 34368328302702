import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import "./AboutUs.css";


function AboutUs(props) {
    const data = props.data;

    return (
        <div className="py-16 md:py-32 m-auto container-cs flex flex-wrap text-center px-4 md:px-0">
            <div className="w-full md:w-1/2 flex flex-wrap text-center md:text-left">
                <div className="w-full main-color text-4xl">
                    <h2 className="ab-h2">
                        {data.title}
                        <em>{data.sectionName}</em>
                    </h2>
                </div>
                <p className="py-2 w-full ab-lead">
                    {data.subtitle}
                </p>

                <p className="py-2 w-full">
                    {data.content}
                </p>

                <div className="flex flex-wrap text-left">
                    {data.contentRepeater && data.contentRepeater.length > 0 && data.contentRepeater.map((dt, i) => (
                        <div className="w-full md:w-1/2 ab-rep-div" key={`ab-repeater-${i}`}>
                            <div className="flex rep-f">
                                <GatsbyImage
                                    image={dt.icon.localFile.childImageSharp.gatsbyImageData}
                                    alt={dt.icon.alt} />
                                <h3 className="pl-4 ab-h3">{dt.title}</h3>
                            </div>
                            <div className="ab-rep-editor" dangerouslySetInnerHTML={{ __html: dt.content }} />
                        </div>
                    ))}
                </div>

                <div className="w-full mt-4">
                    <div className="main-btn-container">
                        <Link to={props.lang.toLowerCase() === "el" ? "/about" : `/${props.lang.toLowerCase()}/about`} className="main-button-blue mt-4 md:mt-0">
                            {data.button}
                        </Link>
                    </div>
                </div>
            </div>
            {/* <div className="w-full md:w-1/2 block mt-8 md:mt-0">
                <GatsbyImage
                    image={data.image.localFile.childImageSharp.gatsbyImageData}
                    alt={data.image.altText}
                    className="m-auto"
                />
            </div> */}
            <div className="w-full md:w-1/2 block mt-8 md:mt-0">
                <img
                    src={data.image.sourceUrl}
                    alt={data.image.altText}
                    className="m-auto"
                />
            </div>
        </div>
    );
}

export default AboutUs;

export const query = graphql`fragment AboutUsFragment on WpPage_Homepageacf {
  whoWeAre {
    sectionName
    title
    content
    subtitle
    button
    image {
      sourceUrl
      altText
    }
  }
}
`