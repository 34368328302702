import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, graphql } from "gatsby"

import "./HomeCarousel.css";

const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                dots: true,
                infinite: true,
                speed: 2500,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: false,
                infinite: true,
                speed: 2500,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
            }
        }
    ]
};

function HomeCarousel(props) {
    const data = props.data;
    console.log(data)
    return (
        <div>
            <Slider {...settings}>
                {props.data.map((item, i) => (
                    <div key={`home-carousel${i}`}>
                        <div style={{ display: "grid" }}>
                            {item.image && (
                                <div
                                    style={{
                                        gridArea: "1/1",
                                        height: "100vh"
                                    }}
                                    className="full-h-gi"
                                >
                                    <img
                                        alt={item.image ? item.image.altText : ""}
                                        loading="eager"
                                        src={item.image.sourceUrl}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            display: "block" // ensure no extra spacing is added
                                        }}
                                    />
                                </div>
                            )}

                            <div
                                style={{
                                    gridArea: "1/1",
                                    position: "relative",
                                    placeItems: "center",
                                    display: "grid",
                                    boxShadow: "rgb(0 0 0 / 85%) 0px 21px 246px 0px inset"
                                }}
                            >
                                {/* Any content here will be centered in the component */}
                                <div className="container m-auto absolute w-full px-4" style={{ bottom: "50px" }}>
                                    {i === 0 ? (
                                        <h1 className="carousel-intro-h">{item.title}</h1>
                                    ) : (
                                        <h2 className="carousel-intro-h">{item.title}</h2>
                                    )}
                                    <div className="flex flex-wrap mt-4">
                                        <p className="carousel-intro-p" style={{ minHeight: "75px" }}>
                                            {item.subtitle}
                                        </p>
                                        {item.button.text && (
                                            <div>
                                                <a href={item.button.link ? item.button.link : "#"} className="c-intro-link">
                                                    {item.button.text}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>

        </div>
    );
}

export default HomeCarousel;

export const query = graphql`fragment HomeCarouselFragment on WpPage_Homepageacf {
    carousel{
        title
        subtitle
        button{
            link
            text
        }
        image {
            sourceUrl
            altText
        }
    }
}
`