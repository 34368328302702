import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Services.css";
import "./WorkProcess.css";


function WorkProcess(props) {
    const data = props.data;
    return (
        <div className="pt-16 md:pt-16 container-cs m-auto text-center">
            <h2 className="dk-title">
                <span>{data.sectionName}</span>
                <span>{data.title}</span>
            </h2>
            <p className="m-auto mt-4 text-xl px-4" >{data.description}</p>

            <div className="py-8 md:py-16 flex flex-wrap container m-auto" >

                {data.workProcesses.map((wp, index) => (
                    <div className="w-full md:w-1/2 flex relative" key={`workProcess-${index}`}>
                        <div className="m-8 m-1 relative flex flex-wrap md:flex-nowrap justify-center">
                            <div className="flex wp-icon-cont">
                                <div className="wp-icon">
                                    <img
                                        src={wp.icon.sourceUrl}
                                        alt={wp.icon.altText}
                                        className="m-auto"
                                    />

                                </div>
                            </div>
                            <div className="text-left">
                                <h3 className="text-2xl py-4 px-6 text-center md:text-left">
                                    {htmlDecode(wp.title)}
                                </h3>
                                <p className="px-6 text-center md:text-left">
                                    {wp.description}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WorkProcess;
