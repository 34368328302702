import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import "./CoreValues.css";


function CoreValues(props) {
    const data = props.data;
    console.log(data)

    return (
        <div className="flex flex-wrap core-values">
            <div
                className="w-full"
                style={{
                    display: "grid",
                }}
            >
                <img
                    src={data.background.sourceUrl}
                    alt={data.background.altText}
                    loading="eager"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover" // This makes the image cover the container
                    }}
                    className="block my-cs-grid darken"
                />
                <div
                    style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "center",
                        display: "grid"
                    }}
                >
                    <div className="block container px-4 py-4 md:py-0">
                        <h2 className="dk-title-action">
                            <span>{data.title1}</span>
                            <span>{data.title2}</span>
                        </h2>
                        <p className="lead-action py-2">{data.content}</p>
                        <div className="flex flex-wrap justify-center mt-8">
                            {data.points && data.points.length > 0 &&
                                data.points.map((item, i) => (
                                    <div
                                        key={`contribution-${i}`}
                                        className="w-1/2 md:w-1/4 text-center text-white"
                                    >
                                        <div>
                                            <img
                                                src={item.icon.sourceUrl}
                                                alt={item.icon.altText}
                                                className="m-auto"
                                            />
                                        </div>
                                        <div className="action-number">{item.number}</div>
                                        <div className="action-tt">{item.title}</div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CoreValues;

export const query = graphql`fragment OurActionFragment on WpPage_Homepageacf {
    ourAction{
        title1
        title2
        content
        background{
            altText
            sourceUrl
            
        }
        points{
            title
            number
            icon{
                altText
                sourceUrl
            }
        }
    }
}
`